<template lang="html">
    <div class="container-fluid p-5">
        <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                       <a href="javascript:void(0)" class="text-info" @click="back()"> <i class="fas fa-arrow-left"></i></a> Product Image
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="table-responsive">
                                    <table class="table table-light">
                                        <thead>
                                            <tr>
                                                <th v-for="(value, key) in columns">
                                                    <a href="#" class="text-capitalize tbl_th_a">
                                                        {{value}}
                                                    </a>
                                                </th>
                                                <th class="th-width-100"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(data,index) in dataList">
                                                <td>{{data.id}}</td>
                                                <td><img :src="env.mediaUrl+data.image" class="tbl_image" /></td>
                                                <td style="padding: 0px;">
                                                    <button v-b-modal.bv-modal-delete
                                                        class="btn btn-option text-danger"><i class="fas fa-trash-alt"
                                                            @click="deleteData(data)"></i></button>
                                                </td>
                                            </tr>
                                            <tr v-if="dataList.length <= 0 ">
                                                <td colspan="7" class="text-center">Empty List</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="bv-modal-delete" ref="my-modal" title="Delete" hide-footer>
            <p class="my-4">Are you sure to delete ? </p>
            <b-button class="mt-3 btn-danger" block @click="deleteItem()">Delete</b-button>
        </b-modal>
        <b-modal id="bv-modal-create" ref="my-modal" title="Add SKU" hide-footer style="width: 170%">
            <template v-slot:modal-name>
                Add Image
            </template>
            <div class="d-block text-center">
                <div class="form-group row">
                    <div class="col-sm-12">
                        <input type="file" class="form-control" id="inputImage" @change="readImage">
                        <img v-if="skuRequest.image" v-bind:src="image" class="banner-image mt-2" style="width: 200px;"/>
                        <small class="text-danger">{{validationData.image}}</small>
                    </div>
                </div>
            </div>
            <b-button class="mt-3 btn-success" block @click="onCreate()">Save</b-button>
        </b-modal>
        <router-link class="float" to="" v-b-modal.bv-modal-create @click="newData()">
            <i class="fa fa-plus my-float" style="padding: 37%;"></i>
        </router-link>
    </div>
</template>

<script lang="js">
    import {
        mapState,
        mapActions
    } from 'vuex'
    import env from '../../enviorments'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        name: 'itemList',
        components: {
            Loading
        },
        data() {
            return {
                env,
                isLoading: false,
                fullPage: true,
                alert: "",
                id: '',
                currentImage: '',
                image: null,
                dataList: [],
                variationList: [],
                columns: {
                    id: "ID",
                    image: "Image",

                },
                skuRequest: {
                    productId: "",
                    image: "",
                },
                filter: {
					page: "",
                    orderBy: "",
                    sortKey: ""
				},
                validationData: {
                    image: ""
				}
            }
        },
        watch: {
            '$route'(to, from) {
                this.fetchData()
            }
        },
        computed: {},
        methods: {
            ...mapActions({
                skuListAction: 'skuListAction',
                skuDeleteAction: 'skuDeleteAction',
                skuStoreAction: 'skuStoreAction',
            }),
            readImage(event) {
				this.skuRequest.image = event.target.files[0];
				//For Show
				const files = event.target.files[0]
				const fileReader = new FileReader()
				fileReader.readAsDataURL(files)
				fileReader.onload = e => {
					this.image = e.target.result
					this.validationRule()
				};
			},
            async onCreate() {
				this.isLoading = true
				let validation = this.validationRule()
				if (validation == true) {
					this.isLoading = false
					return
				}
		
				const formData = new FormData();
				formData.append('productId', this.skuRequest.productId);  
				formData.append('image', this.skuRequest.image); 

				await this.skuStoreAction(
					formData
				).then(res => {
					this.fetchData()
                    this.skuRequest.image = null
                    this.$refs['my-modal'].hide()
                    this.isLoading = false;
				}).catch(err => this.isLoading = true)
			},
            async fetchData() {
                this.isLoading = true
                let option = {
                    orderBy:"desc",
                    sortKey: "created_at",
                    limit: 10,
                    productId : this.skuRequest.productId
                    }
                await this.skuListAction({
                    ...option
                }).then(res => {
                    if (res.status == "success") {
                        this.dataList = res.data.data
                        this.$router.replace({ path: 'sku-list', query: {page: this.filter.page, orderBy: this.filter.orderBy, sortKey: this.filter.sortKey, productId:this.skuRequest.productId} }).catch(()=>{})
                        this.isLoading = false;
                    }
                }).catch(err => this.isLoading = true)
            },
            back() {
				this.$router.replace({ path: 'product-list', query: { page: this.filter.page, orderBy: this.filter.orderBy, sortKey: this.filter.sortKey} }).catch(()=>{})
			},
            deleteData(data) {
                this.id = data.id
            },
            async deleteItem() {
                this.isLoading = true
                let option = {
                    id: this.id,
                }
                await this.skuDeleteAction({
                    ...option
                }).then(res => {
                    this.fetchData()
                    this.$refs['my-modal'].hide()
                    this.isLoading = false;
                }).catch(err => this.isLoading = true)
            },
            validationRule() {
				let isValidation = []
				if (this.skuRequest.iamge == "") {
					this.validationData.iamge = "The image field is required."
					isValidation.push(true)
				} else {
					this.validationData.iamge = ""
					isValidation.push(false)
				}

				if (isValidation.includes(true)) {
					return true
				} else {
					return false
				}
            }

        },
        mounted() {
            this.filter.page = this.$route.query.page
            this.filter.orderBy = this.$route.query.orderBy
            this.filter.sortKey = this.$route.query.sortKey
            this.filter.prodictId = this.$route.query.prodictId
            this.skuRequest.productId = this.$route.query.productId
            this.fetchData()
            this.getFields()
        }
    }
</script>

<style scoped lang="scss">
    .action {
        border: none;
        background: none;
        font-size: 24px;
    }
    .modal-content {
        width: 800px !important;
    }
</style>